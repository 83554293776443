import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AgenteModel} from '../../models/agente.model';
import {AgenteService} from '../agente/agente.service';
import {UtilService} from './util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  agentModel: AgenteModel;

  logged = false;
  authenticationState = new BehaviorSubject(this.logged);

  constructor(
    private agenteService: AgenteService,
    private utilService: UtilService
  ) {
  }

  canActivate(): boolean {
    return this.authenticationState.value;
  }

  async login(data: any) {
    this.agentModel = await this.agenteService.login(data);
    if (this.agentModel) {
      this.logged = true;
      this.authenticationState.next(this.logged);
    }
  }

  logout() {
    this.utilService.clearAllCachedStorage();
    this.agentModel = null;
    this.logged = false;
    this.authenticationState.next(this.logged);
  }

  async checkToken() {
    this.agentModel = await this.agenteService.getAgenteCached();
    if (this.agentModel) {
      this.logged = true;
      this.authenticationState.next(this.logged);
    } else {
      this.utilService.clearAllCachedStorage();
    }
  }

  async refreshAgentLogged() {
    if (this.agentModel) {
      const nowDate = new Date();
      const logadoEm = new Date(this.agentModel.logadoEm);
      const futureDate = new Date(logadoEm.getTime() + 360*60000);
      if (nowDate.getTime() >= futureDate.getTime()) {
        const agent = await this.agenteService.refreshToken();
        if (agent) {
          this.agentModel = agent;
        } else {
          this.logout();
        }
      }
    }
  }
}
