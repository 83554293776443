import { Injectable } from '@angular/core';
import { AppSettings } from '../../helpers/app-settings';
import { OfflineService } from '../common/offline.service';
import { AgenteModel } from '../../models/agente.model';

@Injectable({
  providedIn: 'root'
})
export class AgenteService extends OfflineService {

  getTableIdentifier(): string {
    return 'agente';
  }

  async login(dados: any): Promise<AgenteModel> {
    let agent: AgenteModel = null;
    try {
      await this.utilService.presentLoading({
        message: 'Realizando login.. '
      });
      var result = await this.postToApi(AppSettings.AGENTE_LOGIN, dados, true, true);
      agent = result.data
    } catch (err) {
      if (err.status != 500) {
        await this.utilService.presentAlert({
          header: 'Não foi possível logar',
          message: err.error.message,
          buttons: ['Ok']
        });
      }
    }

    await this.utilService.dismissLoading();
    return agent;
  }

  async refreshToken() {
    try {
      return await this.getFromApi(AppSettings.REFRESH_TOKEN, `cache:${this.getTableIdentifier()}`);
    } catch (err) {
      return null;
    }
  }

  getAgenteCached() {
    return this.storage.get(`cache:${this.getTableIdentifier()}`);
  }
}
