import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  const firebaseConfig = {
    apiKey: 'AIzaSyARP27P9Nuy3hruGgXqxjmm5ENEV88YJi4',
    authDomain: 'simo-webapp.firebaseapp.com',
    projectId: 'simo-webapp',
    storageBucket: 'simo-webapp.appspot.com',
    messagingSenderId: '942367409361',
    appId: '1:942367409361:web:72a2a7d83539b08e34b1f0',
    measurementId: 'G-N330QKB6CY'
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  Sentry.init({
    dsn: 'https://7d221264de7c465188df619ce7e796c7@o1168821.ingest.sentry.io/4505307586494464',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'sistemasimo.com.br', environment.apiEndpoint, /^\b(?:\w+\.)?sistemasimo\.com.br\b/],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  enableProdMode();
}




platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

defineCustomElements(window);
