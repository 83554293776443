import {Component, OnInit} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {AuthenticationService} from './services/common/authentication.service';
import {NavController} from '@ionic/angular';
import {ConfigurationService} from './services/configuration/configuration.service';
import {SwUpdate} from '@angular/service-worker';
import {interval} from 'rxjs';
import {AtividadesService} from './services/atividades/atividades.service';
import {UtilService} from './services/common/util.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private storage: Storage,
    private swUpdate: SwUpdate,
    private authService: AuthenticationService,
    private configuration: ConfigurationService,
    private atividadeService: AtividadesService,
    private utilService: UtilService,
    private nav: NavController
  ) {
  }

  async ngOnInit() {
    await this.storage.create();
    await this.configuration.getConfiguration();
    await this.authService.checkToken();

    this.authService.authenticationState.subscribe(state => {
      if (state) {
        this.nav.navigateRoot('simo', {replaceUrl: true});
      } else {
        this.nav.navigateRoot('login', {replaceUrl: true});
      }
    });

    this.configuration.configurationState.subscribe(state => {
      if (!state) {
        this.nav.navigateRoot('sem-configuracao', {replaceUrl: true});
      }
    });

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.utilService.presentAlert({
          header: 'Nova versão!',
          subHeader: 'Precisamos atualizar o aplicativo com a versão mais recente.',
          buttons: [
            {
              text: 'Atualizar agora',
              handler: () => {
                window.location.reload();
              }
            }
          ]
        });
      });
    }

    let ultimaExportacao = new Date();
    const minuteIntervalValid$ = interval(1000);
    minuteIntervalValid$.subscribe(() => {
      this.authService.refreshAgentLogged();

      const nowDate = new Date();
      const nextExportation = new Date(ultimaExportacao.getTime() + 60000);
      if (nowDate.getTime() >= nextExportation.getTime()) {
        ultimaExportacao = nowDate;
        this.atividadeService.exportarDados();
      }
    });

  }
}
