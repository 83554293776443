import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthenticationService} from './services/common/authentication.service';

const routes: Routes = [
  {path: '', redirectTo: 'simo', pathMatch: 'full'},
  {
    path: 'simo',
    canActivate: [AuthenticationService],
    loadChildren: () => import('./pages/simo/simo-routing.module').then(m => m.SimoRoutingModule)
  },
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'sem-configuracao',
    pathMatch: 'full',
    loadChildren: () => import('./pages/sem-configuracao/sem-configuracao.module').then(m => m.SemConfiguracaoPageModule)
  },
  {
    path: 'qrcode-scan',
    loadChildren: () => import('./components/qrcode-scan/qrcode-scan.module').then( m => m.QrcodeScanPageModule)
  },
  {
    path: 'pdf-viewer',
    loadChildren: () => import('./components/pdf-viewer/pdf-viewer.module').then( m => m.PdfViewerPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
