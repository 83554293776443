import { Injectable, Component } from '@angular/core';
import { OfflineService } from '../common/offline.service';
import { AtividadesModel } from '../../models/atividades.model';
import { OcorrenciasModel } from '../../models/ocorrencias.model';
import { AtividadesHistoricoModel } from '../../models/atividadesHistorico.model';
import { ProvidenciasModel } from '../../models/providencias.model';
import { AppSettings } from '../../helpers/app-settings';
import { EmailModel } from '../../models/email.model';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})
export class AtividadesService extends OfflineService {
  exportaTime = 60;
  exportaConexao = true;
  exportaWaiting = true;

  getTableIdentifier(): string {
    return 'atividades';
  }

  async setOcorrenciaOpened(ocorrencia: OcorrenciasModel) {
    const atividade: AtividadesModel = new AtividadesModel();

    atividade.agente = this.authService.agentModel.agente;
    atividade.ocorrencias_codigoq = ocorrencia.codigoq;
    atividade.ocorrencias_descricao = ocorrencia.descricao;
    atividade.subtitulo = ocorrencia.subtitulo;
    atividade.ocorrencia = ocorrencia.id;
    atividade.central = this.authService.agentModel.central;
    atividade.posto = this.authService.agentModel.posto;
    atividade.iniciou = this.utilService.getDateNow();
    atividade.hash = this.utilService.criarHash();
    atividade.lhistorico = 0;
    atividade.lpanico = ocorrencia.lpanico;
    atividade.apelidodispositivo = this.getApelidoDevice();

    const emailModel = new EmailModel();
    if (ocorrencia.emails) {
      ocorrencia.emails.split(',').forEach(item => {
        emailModel.emails.push({
          to: item,
          remove: false
        });
      });
    }
    emailModel.enviar_abertura = ocorrencia.lenviarabertura === '1';
    emailModel.enviar_monitoramento = ocorrencia.lenviarmonitoramento === '1';
    emailModel.enviar_fechamento = ocorrencia.lenviarfechamento === '1';
    emailModel.ocorrencia_desc = ocorrencia.descricao;
    emailModel.central_nome = ocorrencia.central_nome;
    atividade.email = emailModel;

    const historicos: AtividadesHistoricoModel[] = [];
    if (ocorrencia.providencias && ocorrencia.providencias.length > 0) {
      ocorrencia.providencias.forEach((providencia: ProvidenciasModel) => {
        historicos.unshift({
          data: this.utilService.getDateNow(),
          nova: false,
          isAssinatura: false,
          isMidia: false,
          hash: atividade.hash,
          hashhistorico: this.utilService.criarHash(providencia.sequencia.toString()),
          providencia: providencia.id,
          detalhes: providencia.detalhes,
          efetivado: '',
          isEfetivado: false,
          blockEfetivar: false,
          lrequerido: providencia.lrequerido,
          midia: '',
          formato: '',
          lmidiagaleria: 0,
          lqrcode: providencia.lqrcode,
          leditaqrcode: providencia.leditaqrcode,
          lfotoqrcode: providencia.lfotoqrcode,
          midiaqrcode: '',
          nleuqrcode: '',
          formdocxml: providencia.formdocxml,
          linicia: 0,
          lobrigatoria: providencia.lobrigatoria,
          operador: this.authService.agentModel.agente_apelido,
          coperador: this.authService.agentModel.agente_apelido,
          formulario: providencia.formulario
        });
      });
    }

    atividade.historicos = historicos;

    await this.setViewOpened(atividade);
  }

  async registrarOcorrenciaOrRascunho(atividade: AtividadesModel, isRascunho: boolean = false) {
    const identifierTable = isRascunho ? `rascunho:${this.getTableIdentifier()}:${atividade.hash}` :
      `exporta:${this.getTableIdentifier()}:${atividade.hash}`;
    await this.storage.set(identifierTable, atividade);
  }

  async getRegistrosOrRascunhos(isRascunho: boolean = false) {
    const identifierTable = isRascunho ? `rascunho:${this.getTableIdentifier()}` : `exporta:${this.getTableIdentifier()}`;
    return this.getStorageIdentifierInitWith(identifierTable);
  }

  async removerRascunho(atividade: AtividadesModel) {
    const identifierTable = `rascunho:${this.getTableIdentifier()}:${atividade.hash}`;
    await this.storage.remove(identifierTable);
  }

  async removerExportacao(atividade: AtividadesModel) {
    const identifierTable = `exporta:${this.getTableIdentifier()}:${atividade.hash}`;
    await this.storage.remove(identifierTable);
  }
  async exportarDados() {
    const atividadesEncontradas = await this.getRegistrosOrRascunhos();
    if (atividadesEncontradas) {
      this.exportaConexao = await this.isOnline();

      if (this.exportaConexao && this.exportaWaiting && atividadesEncontradas.length > 0) {
        this.exportaWaiting = false;
        const config = await this.getConfiguration();
        const agente = await this.getAgent();

        for (const atividade of atividadesEncontradas) {
          if (!atividade.exportationHasWarningError && !atividade.exportationHasError) {
            const identifierTable = `exporta:${this.getTableIdentifier()}:${atividade.hash}`;
            await this.registrarAtividade(atividade, atividade.finalizar).then((result) => {
              if (result['savedAndFinished']) {
                this.storage.remove(identifierTable);
              } else {
                atividade.exportationButtonColor = '--ion-color-medium: yellow'
                atividade.exportationHasWarningError = true
                atividade.exportationError = result['errorData'];
                atividade.exportationErrorMessage = result['message'];
                this.registrarOcorrenciaOrRascunho(atividade, false)
              }
            }).catch(exp => {
              atividade.exportationButtonColor = '--ion-color-medium: red'
              atividade.exportationHasError = true
              atividade.exportationError = JSON.stringify(exp.error.error);
              atividade.exportationErrorMessage = exp.error.message;
              this.registrarOcorrenciaOrRascunho(atividade, false)
            });

            this.utilService.publishEvent('sincronizacao:exporta', {});
          }
        }
        this.exportaWaiting = true;
      }
    }
  }

  async registrarAtividade(atividade, finalizar: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.postToApi(AppSettings.ATIVIDADES_REGISTRAR, { atividade: atividade }, false).then(() => {
        if (!finalizar) {
          return resolve({
            savedAndFinished: true,
            errorData: null
          })
        }
        this.getFromApi(AppSettings.ATIVIDADES_DUAS_VIAS_FINALIZAR + atividade.hash, null).then(() => {
          return resolve({
            savedAndFinished: true,
            message: 'Atividade finalizada com sucesso',
            errorData: null
          })
        }).catch(err => {
          const errorText = JSON.stringify(err.error.error);
          return resolve({
            savedAndFinished: false,
            message: err.error.message,
            errorData: errorText
          })
        });
      }).catch(err => {
        reject(err)
      })
    })


    return
  }

  async emailAndamento(atividade) {
    atividade.email.enviar_monitoramento = true;
    return this.postToApi(AppSettings.ATIVIDADES_EMAIL_ANDAMENTO, atividade, false, true);
  }

  async getAtividadesDuasVias(filtroProvidencias: string = "") {
    const agente = await this.storage.get(`cache:agente`);
    return this.getFromApi(AppSettings.ATIVIDADES_DUAS_VIAS.replace('central_X0', agente.central).replace("filtro_X1", filtroProvidencias), null, true);
  }

  getAtividadeDuasViasSelecionado(hashAtividade: string) {
    return this.getFromApi(AppSettings.ATIVIDADES_DUAS_VIAS_HISTORICO + hashAtividade, null, true)
  }

  solicitarEdicaoAtividade(hashAtividade: string) {
    return this.getFromApi(AppSettings.ATIVIDADES_DUAS_VIAS_SOLICITAR_EDICAO + hashAtividade, null, true)
  }

  async liberarEdicaoAtividade(hashAtividade: string) {
    return this.getFromApi(AppSettings.ATIVIDADES_DUAS_VIAS_LIBERAR_EDICAO + hashAtividade, null, true);
  }

  async finalizarAtividade(hashAtividade: string) {
    return this.getFromApi(AppSettings.ATIVIDADES_DUAS_VIAS_FINALIZAR + hashAtividade, null, true);
  }

  podeFinalizar(atividade: AtividadesModel) {
    const finaliza = {
      ok: true,
      message: ''
    };

    atividade.historicos.forEach((historico) => {
      if (!historico.efetivado) {
        if (historico.lobrigatoria == 1) {
          finaliza.ok = false;
          finaliza.message = `A providência ${historico.detalhes} precisa ser efetivada, pois ela é obrigatória!`;
        }
      }
    });

    return finaliza;
  }
}
