import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UtilService} from '../../services/common/util.service';
import {EmailModel} from '../../models/email.model';
import * as _ from 'lodash';
import {AtividadesService} from '../../services/atividades/atividades.service';
import {AtividadesModel} from '../../models/atividades.model';

@Component({
  selector: 'app-mail-config',
  templateUrl: './mail-config.component.html',
  styleUrls: ['./mail-config.component.scss'],
})
export class MailConfigComponent implements OnInit, OnDestroy {

  @Input() emailModel: EmailModel;
  @Input() atividade: AtividadesModel;
  emailModelChanged: EmailModel;
  atividadeChanged: AtividadesModel;
  emailInput = '';
  disableSendButton = false;

  constructor(private modalCtrl: ModalController, private utilService: UtilService, private atividadeService: AtividadesService) {
  }

  @HostListener('window:popstate', ['$event'])
  public async cancel() {
    if (!_.isEqual(this.emailModelChanged, this.emailModel)) {
      await this.utilService.presentAlert({
        header: 'Atenção',
        message: 'Você tem alterações não salvas, salvar as alterações?',
        buttons: [
          {
            text: 'Não',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.modalCtrl.dismiss(null, 'cancel');
            }
          },
          {
            text: 'Sim',
            handler: () => {
              this.modalCtrl.dismiss(this.emailModelChanged, 'confirm');
            }
          }
        ]
      });
    } else {
      await this.modalCtrl.dismiss(null, 'cancel');
    }
  }

  ngOnInit() {
    this.emailModelChanged = _.cloneDeep(this.emailModel);
    this.atividadeChanged = _.cloneDeep(this.atividade);
    const modalState = {
      modal: true,
      desc: 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  async salvar() {
    this.modalCtrl.dismiss(this.emailModelChanged, 'confirm-send');
  }

  async enviarEmail() {
    const isOnline = await this.atividadeService.isOnline();
    let messageToast = 'Você está sem internet no momento!';
    let emailEnviado = false;
    if (isOnline) {
      this.disableSendButton = true;
      this.atividadeChanged.email = this.emailModelChanged;
      await this.atividadeService.emailAndamento(this.atividadeChanged).then((result) => {
        messageToast = result.message;
        emailEnviado = result.status;
        this.disableSendButton = false;
      }).catch(async exp => {
        if (exp.status != 500) {
          await this.utilService.presentAlert({
            header: 'Não foi possível enviar e-mail',
            message: exp.error.message,
            buttons: ['Ok']
          });
        }
      });
      
    }

    if (emailEnviado) {
      this.modalCtrl.dismiss(this.emailModelChanged, 'confirm-send');
    }

    return this.utilService.presentToast({
      message: messageToast,
      duration: 5000,
      color: emailEnviado ? 'primary' : 'danger',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    });
  }

  addEmail() {
    // eslint-disable-next-line max-len
    const validEmail = String(this.emailInput).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const exist = this.emailModelChanged.emails.some(e => e.to === this.emailInput);


    if (!validEmail || exist) {
      return this.utilService.presentToast({
        message: !validEmail ? 'E-mail informado não é válido!' : 'E-mail já informado!',
        duration: 5000,
        color: 'danger',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel'
          }
        ]
      });
    }

    this.emailModelChanged.emails.push({
      to: this.emailInput,
      remove: true
    });
    this.emailInput = '';
  }
}
