import {BaseModel} from './base.model';

export class EmailModel implements BaseModel {
  emails = [];
  texto_adicional: string;
  ocorrencia_desc: string;
  central_nome: string;
  enviar_abertura: boolean;
  enviar_fechamento: boolean;
  enviar_monitoramento: boolean;
}
