import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy, Router} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {IonicStorageModule} from '@ionic/storage-angular';
import {HttpClientModule} from '@angular/common/http';
import {TitleCasePipe} from './pipes/title-case.pipe';
import {WINDOW_PROVIDERS} from './window.providers';
import {ConfigurationService} from './services/configuration/configuration.service';
import {Drivers} from '@ionic/storage';
import {NetworkWeb} from '@capacitor/network/dist/esm/web';
import {NgxIonicImageViewerModule} from 'ngx-ionic-image-viewer';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import {MailConfigComponent} from './components/mail-config/mail-config.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import * as Sentry from '@sentry/angular-ivy';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent, TitleCasePipe, MailConfigComponent],
  entryComponents: [],
  imports: [
    NgxMaskModule.forRoot(),
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__simoDb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    AppRoutingModule,
    HttpClientModule,
    NgxIonicImageViewerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FormsModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    WINDOW_PROVIDERS,
    ConfigurationService,
    NetworkWeb,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
