import {Injectable} from '@angular/core';
import {AppSettings} from '../../helpers/app-settings';
import {OfflineService} from '../common/offline.service';
import {ConfigurationModel} from '../../models/configuration.model';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends OfflineService {
  configurationModel: ConfigurationModel;
  configurationLoaded = false;

  configurationState = new BehaviorSubject(this.configurationLoaded);


  getTableIdentifier(): string {
    return 'configuration';
  }

  systemLoad() {
    this.configurationLoaded = true;
    this.configurationState.next(this.configurationLoaded);
  }

  systemUnload() {
    this.configurationLoaded = false;
    this.configurationState.next(this.configurationLoaded);
    this.utilService.clearAllCachedStorage();
  }

  async getConfiguration() {
    const hasConfigCached = await this.getCachedConfiguration();
    const isOnline = await this.isOnline();

    if (hasConfigCached) {
      this.systemLoad();
    } else {
      if (!isOnline) {
        this.systemUnload();
      } else {
        try {
          this.configurationModel = await this.getFromApi(AppSettings.CONFIGURATION_MOBILE + this.getSubdomain(),
            `system:${this.getTableIdentifier()}`);
          this.systemLoad();
        } catch (error) {
          return this.systemUnload();
        }
      }
    }
  }

  async deleteConfiguration() {
    await this.storage.remove(`system:${this.getTableIdentifier()}`);
  }

  private async getCachedConfiguration() {
    const config = await this.storage.get(`system:${this.getTableIdentifier()}`);
    if (!config) {
      return false;
    }

    this.configurationModel = config;
    if (config.subdomain === this.getSubdomain()) {
      this.systemLoad();
    } else {
      this.systemUnload();
    }

    return true;
  }

}
