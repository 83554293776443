import { AtividadesHistoricoModel } from './atividadesHistorico.model';
import { BaseModel } from './base.model';
import { EmailModel } from './email.model';

export class AtividadesModel implements BaseModel {
  id: number;
  agente: number;
  ocorrencia: number;
  central: number;
  posto: number;
  iniciou: string;
  terminou: string;
  hash: string;
  lhistorico: number;
  lpanico: number;
  delivery: number;
  subtitulo: string;
  emuso: string;
  emusodados: string;
  apelidodispositivo: string;
  critico: string;
  alerta: string;
  normal: string;
  ativa_critico: boolean;
  ativa_alerta: boolean;
  ativa_normal: boolean;
  ocorrencias_codigoq: string;
  ocorrencias_descricao: string;
  ocorrencias_subtitulo: string;
  ocorrencias_cor: string;
  ocorrencias_cordotexto: string;
  ocorrencias_lpulsarnormal: boolean;
  ocorrencias_lpulsaralerta: boolean;
  ocorrencias_lpulsarcritico: boolean;
  agente_apelido: string;
  posto_nome: string;
  lmostrarid: number;
  central_nome: string;
  finalizar: boolean;
  email: EmailModel;
  historicos: AtividadesHistoricoModel[];
  exportationButtonColor: string;
  exportationHasWarningError: boolean;
  exportationHasError: boolean;
  exportationErrorMessage: string;
  exportationError: string;
}

