import {Injectable} from '@angular/core';
import {Md5} from 'ts-md5';
import {AlertController, LoadingController, ToastController} from '@ionic/angular';
import {Subject, Subscription} from 'rxjs';
import {Storage} from '@ionic/storage';
// @ts-ignore
import dateAndTime from 'date-and-time';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private hasToastShow = false;
  private hasLoadingShow = false;
  private hasAlertShow = false;
  private eventsChannels: { [key: string]: Subject<any> } = {};


  constructor(
    private toastController: ToastController,
    private loadingController: LoadingController,
    private storage: Storage,
    private alertCtrl: AlertController
  ) {
  }

  //region [TOAST]
  async presentToast(options: any) {
    if (this.hasToastShow) {
      await this.toastController.dismiss();
      this.hasToastShow = false;
    }
    await this.toastController.create(options).then(a => {
      this.hasToastShow = true;
      a.present().then(() => {
        a.onWillDismiss().then(() => {
          this.hasToastShow = false;
        });
      });
    });
  }

  async dismissToast() {
    if (this.hasToastShow) {
      await this.toastController.dismiss();
      this.hasToastShow = false;
    }
  }

  //endregion

  //region [LOADING]
  async presentLoading(options: any) {
    if (this.hasLoadingShow) {
      await this.loadingController.dismiss();
      this.hasLoadingShow = false;
    }

    await this.loadingController.create(options).then(a => {
      this.hasLoadingShow = true;
      a.present().then(() => {
        a.onWillDismiss().then(() => {
          this.hasLoadingShow = false;
        });
      });
    });
  }

  async dismissLoading() {
    if (this.hasLoadingShow) {
      await this.loadingController.dismiss();
      this.hasLoadingShow = false;
    }
  }

  //endregion

  //region [Alert]
  async presentAlert(options: any) {
    if (this.hasAlertShow) {
      await this.alertCtrl.dismiss();
      this.hasAlertShow = false;
    }
    await this.alertCtrl.create(options).then(a => {
      this.hasAlertShow = true;
      a.present().then(() => {
        a.onWillDismiss().then(() => {
          this.hasAlertShow = false;
        });
      });
    });
  }

  async dismissAlert() {
    if (this.hasAlertShow) {
      await this.alertCtrl.dismiss();
      this.hasAlertShow = false;
    }
  }

  //endregion

  //region [EVENTS]
  subscribeEvent(topic: string, observer: (_: any) => void): Subscription {
    if (!this.eventsChannels[topic]) {
      this.eventsChannels[topic] = new Subject<any>();
    }

    return this.eventsChannels[topic].subscribe(observer);
  }

  publishEvent(topic: string, data: any): void {
    const subject = this.eventsChannels[topic];
    if (!subject) {
      // Or you can create a new subject for future subscribers
      return;
    }

    subject.next(data);
  }

  destroyEvent(topic: string): null {
    const subject = this.eventsChannels[topic];
    if (!subject) {
      return;
    }

    subject.complete();
    delete this.eventsChannels[topic];
  }

  //endregion


  async clearAllCachedStorage() {
    const keysToRemoveFromStorage = [];

    await this.storage.forEach((value, key, index) => {
      if (key.startsWith(`cache`)) {
        keysToRemoveFromStorage.push(key);
      }
    });

    keysToRemoveFromStorage.forEach(key => {
      this.storage.remove(key);
    });
  }

  criarHash(adicional: string = null) {
    const md5 = new Md5();
    const date = new Date();
    const random = Math.random();
    const time = date.getTime();
    if (adicional) {
      return md5.appendStr(String(random + time + adicional)).end().toString();
    }

    return md5.appendStr(String(random + time)).end().toString();
  }

  getDateNow() {
    const now = new Date();
    return dateAndTime.format(now, 'YYYY-MM-DD HH:mm:ss');
  }

  formatDate(date, format = 'DD/MM/YYYY HH:mm') {
    return dateAndTime.format(date, format);
  }

  getHumanTime(timestamp) {
    const time = Math.abs(timestamp);
    let humanTime;
    let units;

    if (time > (1000 * 60 * 60 * 24 * 365)) {
      humanTime = parseInt(String(time / (1000 * 60 * 60 * 24 * 365)), 10);
      units = humanTime > 1 ? 'Anos' : 'Ano';
    } else if (time > (1000 * 60 * 60 * 24 * 30)) {
      humanTime = parseInt(String(time / (1000 * 60 * 60 * 24 * 30)), 10);
      units = humanTime > 1 ? 'Meses' : 'Mês';
    } else if (time > (1000 * 60 * 60 * 24 * 7)) {
      humanTime = parseInt(String(time / (1000 * 60 * 60 * 24 * 7)), 10);
      units = humanTime > 1 ? 'Semanas' : 'Semana';
    } else if (time > (1000 * 60 * 60 * 24)) {
      humanTime = parseInt(String(time / (1000 * 60 * 60 * 24)), 10);
      units = humanTime > 1 ? 'Dias' : 'Dia';
    } else if (time > (1000 * 60 * 60)) {
      humanTime = parseInt(String(time / (1000 * 60 * 60)), 10);
      units = humanTime > 1 ? 'Horas' : 'Hora';
    } else if (time > (1000 * 60)) {
      humanTime = parseInt(String(time / (1000 * 60)), 10);
      units = humanTime > 1 ? 'Minutos' : 'Minuto';
    } else {
      humanTime = parseInt(String(time / (1000)), 10);
      units = humanTime > 1 ? 'Segundos' : 'Segundo';
    }

    return humanTime + ' ' + units;

  };
}
