import {environment} from '../../environments/environment';

export class AppSettings {
  public static CONFIGURATION_MOBILE = environment.apiEndpoint + 'configuration/info?subdomain=';
  public static AGENTE_LOGIN = environment.apiEndpoint + 'agente/login';
  public static REFRESH_TOKEN = environment.apiEndpoint + 'agente/refreshToken';
  public static OCORRENCIAS_LISTAR = environment.apiEndpoint + 'ocorrencias/listar?central=';
  public static ATIVIDADES_REGISTRAR = environment.apiEndpoint + 'atividades/registrar';
  public static ATIVIDADES_EMAIL_ANDAMENTO = environment.apiEndpoint + 'atividades/email_andamento';
  public static ATIVIDADES_DUAS_VIAS = environment.apiEndpoint + 'atividades/duas_vias?central=central_X0&filtro_providencias=filtro_X1';
  public static ATIVIDADES_DUAS_VIAS_HISTORICO = environment.apiEndpoint + 'atividades/historicos?hash_atividade=';
  public static ATIVIDADES_DUAS_VIAS_SOLICITAR_EDICAO = environment.apiEndpoint + 'atividades/solicitar_edicao?hash_atividade=';
  public static ATIVIDADES_DUAS_VIAS_LIBERAR_EDICAO = environment.apiEndpoint + 'atividades/liberar_edicao?hash_atividade=';
  public static ATIVIDADES_DUAS_VIAS_FINALIZAR = environment.apiEndpoint + 'atividades/finalizar?hash_atividade=';
}
