import {HttpClient, HttpResponse} from '@angular/common/http';
import {NetworkWeb} from '@capacitor/network/dist/esm/web';
import {Storage} from '@ionic/storage';
import {Inject, Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {WINDOW} from '../../window.providers';
import {BaseModel} from '../../models/base.model';
import {UtilService} from './util.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export abstract class OfflineService {

  constructor(
    @Inject(WINDOW) private window: Window,
    protected http: HttpClient,
    protected network: NetworkWeb,
    protected storage: Storage,
    protected authService: AuthenticationService,
    protected utilService: UtilService,
    protected deviceService: DeviceDetectorService
  ) {
  }

  getSubdomain(): string {
    const host = this.window.location.host;
    return host.split('.')[0];
  }

  async isOnline(): Promise<boolean> {
    const status = await this.network.getStatus();

    return status.connected;
  }

  getFromApi(url: string, cacheUrl: string = null, showError: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const headerData: Record<string, any> = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Cache-Control': 'no-cache',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Pragma: 'no-cache',
      };
      const jwt = await this.getJwtToken();
      if (jwt) {
        headerData.Authorization = `Bearer ${jwt}`;
      }
      const identifierData = await this.getIdentifier();
      if (identifierData) {
        headerData.identifier = identifierData;
      }
      this.http.get(url, {
        headers: headerData,
        observe: 'response'
      }).subscribe((response: any) => {
        const dataRetorned = response.body.data;
        if (cacheUrl) {
          this.storage.set(cacheUrl, dataRetorned).then(() => {
            resolve(dataRetorned);
          });
        } else {
          resolve(dataRetorned);
        }
      }, async err => {
        this.verifica401(err);
        if(showError && err.status == 500){
          await this.utilService.presentAlert({
            header: 'Atenção',
            message: err.error.message,
            buttons: [
              {
                text: 'Copiar LOG',
                handler: () => {
                  const errorText = JSON.stringify(err.error.error);
                  navigator.clipboard.writeText(errorText);
                }
              },
              {
                text: 'Ok',
                role: 'cancel',
              }
            ]
          });
        }
        reject(err);
      });
    });
  }

  postToApi(url: string, object: any, saveCache: boolean = false, showError: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const headerData: Record<string, any> = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Cache-Control': 'no-cache',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Pragma: 'no-cache',
      };
      const jwt = await this.getJwtToken();
      if (jwt) {
        headerData.Authorization = `Bearer ${jwt}`;
      }
      const identifierData = await this.getIdentifier();
      if (identifierData) {
        headerData.identifier = identifierData;
      }
      this.http.post(url, JSON.stringify(object), {
        headers: headerData,
        observe: 'response'
      }).subscribe((response: HttpResponse<any>) => {
        const dataRetorned = response.body.data;
        if (saveCache) {
          this.storage.set(`cache:${this.getTableIdentifier()}`, dataRetorned).then(() => {
            resolve(response.body);
          });
        } else {
          resolve(response.body);
        }
      }, async err => {
        this.verifica401(err);
        if(showError && err.status == 500){
          await this.utilService.presentAlert({
            header: 'Atenção',
            message: err.error.message,
            buttons: [
              {
                text: 'Copiar LOG',
                handler: () => {
                  const errorText = JSON.stringify(err.error.error);
                  navigator.clipboard.writeText(errorText);
                }
              },
              {
                text: 'Ok',
                role: 'cancel'
              },
            ]
          });
        }
        reject(err);
      });
    });
  }

  async getFromCache(cacheUrl: string) {
    const cachedModel = await this.storage.get(cacheUrl);
    if (!cachedModel) {
      await this.utilService.presentAlert({
        header: 'Ops',
        message: 'Não há dados em cache',
        buttons: ['Ok']
      });
    }

    return cachedModel;
  }

  async setViewOpened(model: BaseModel) {
    await this.storage.set(`view:${this.getTableIdentifier()}:opened`, model);
  }

  async getViewOpened() {
    return await this.storage.get(`view:${this.getTableIdentifier()}:opened`);
  }

  async removeViewOpened() {
    return await this.storage.remove(`view:${this.getTableIdentifier()}:opened`);
  }

  async getStorageIdentifierInitWith(init: string): Promise<any> {
    const itensFound = [];
    await this.storage.forEach((value, key, index) => {
      if (key.startsWith(init)) {
        itensFound.push(value);
      }
    });

    return itensFound;
  }

  async getIdentifier(): Promise<string> {
    const config = await this.storage.get('system:configuration');
    if (config) {
      return config.identifier;
    }
    return null;
  }

  async getAgent() {
    return await this.storage.get('cache:agente');
  }

  async getConfiguration() {
    return await this.storage.get('system:configuration');
  }

  verifica401(erro) {
    if (erro.status === 401) {
      this.authService.logout();
    }
  }

  getApelidoDevice() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    return deviceInfo.deviceType;
  }

  private async getJwtToken(): Promise<string> {
    const agent = await this.getAgent();
    if (agent) {
      return agent.jwtToken;
    }
    return null;
  }

  abstract getTableIdentifier(): string;

}
